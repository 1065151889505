<template>
  <div>
    <h2>Sintesi legislativa</h2>
    <nav class="law-references-nav">
      <ul v-if="laws.length > 0">
        <li v-for="law in laws" :key="law.id">
          <router-link :to="'#legal-reference-' + law.id">
            {{ law.name }}
          </router-link>
        </li>
      </ul>
    </nav>
    <dl class="law-reference-list">
      <template v-for="law in laws">
        <dt :id="'legal-reference-' + law.id" :data-bookmark="law.id" :key="'law-term-' + law.id">
          {{ law.name }} <span>{{ law.title }}</span>
        </dt>
        <dd v-html="law.html" :key="'law-' + law.id"></dd>
      </template>
    </dl>
  </div>
</template>

<script>

import laws from '@/data/laws.json';

export default {
  data () {
    return {
      laws: laws.data || [],
    };
  },
};

</script>

<style lang="scss">
</style>
